.wrapper {
  padding: 0 20px;
}

.main-container {
  display: flex;
  justify-content: center;
  column-gap: 50px;
}

.component-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

/* Styling for RadioImageButton component */
.selected-radio-icon {
  outline: 3px solid #1976d2;
}
.color-radio-btn-icon {
  width: 30px;
  height: 30px;
}
.radio-btn-icon-max-size {
  max-height: 150px;
  max-width: 150px;
}

/* Styling for TextFieldInput component */
textArea {
  width: 100%;
  padding: 5px;
}
.without-padding div {
  padding: 5px;
}
